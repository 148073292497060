import React from 'react'
import Footer from '../components/footer'
import Header from '../components/header'
import '../styles/index.scss'
import layoutStyles from './layout.module.scss'
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import 'bootstrap/dist/css/bootstrap.min.css';

const ImageBackground = styled(BackgroundImage)`
    background-position: top 20% center;
    background-size: cover;
    height: 100vh;
    background-repeat: repeat-y !important;
    /* override the default margin for sibling elements  */
    + * {
        margin-top: 0;
    }
`;

const Layout = (props) => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulCover {
            edges {
                node {
                    image {
                        fluid(quality: 90, maxWidth: 1920) {
                            src
                        }
                    }
                }          
            }
        }
    }
      `)

    return (
            <div className = {layoutStyles.container}>
                <div className = {layoutStyles.content}>
                    <Helmet>
                        <title>Botond Gagyi</title>
                        <meta name="description" content="Botond Gagyi is a Transylvanian painter currently living and working in Cluj-Napoca, Romania. Browse the paintings on his official page and get in touch.">
                        </meta>
                        <meta name="keywords" content="painting, contemporary, art, transylvanian, gagyi, botond, figurative, oil painting, emerging artist, figurative art, artwork, body, fine art">
                        </meta>
                        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
                        </link>
                    </Helmet>
                    <Header/>    
                        {props.children}
                </div>
                <Footer/>
            </div>
    )
}

export default Layout 