import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, NavBrand, NavMenu } from 'react-bootstrap'
import headerStyles from './header.module.scss'

const Header = () => {

    return (
        <header>
            <Navbar collapseOnSelect expand="md">
                    <Navbar.Brand href="/">
                        <div id="brandName" className={headerStyles.title}>
                            BOTOND GAGYI
                        </div>
                    </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarResponsive" />
                <Navbar.Collapse id="navbarResponsive">
                    <Nav as="ul">
                        <Nav.Item as="li">
                            <Link to="/paintings" className="nav-link" activeClassName="active-nav-link">
                                    PAINTINGS
                            </Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                        <Link to="/about" className="nav-link" activeClassName="active-nav-link">
                                ABOUT
                        </Link>
                        </Nav.Item>
                        
                        <Nav.Item as="li">
                        <Link to="/press" className="nav-link" activeClassName="active-nav-link">
                                PRESS
                        </Link>
                        </Nav.Item>

                        <Nav.Item as="li">
                        <Link to="/contact" className="nav-link" activeClassName="active-nav-link">
                                CONTACT
                        </Link>
                        </Nav.Item>                                      
                    </Nav>
                </Navbar.Collapse>
            </Navbar> 

            <div id="instaHeaderContainerPages">
                <a id="instaLogoHeaderPages" class="fa fa-instagram" href="https://www.instagram.com/gagyi.botond"></a>
            </div>
            <div id="facebookHeaderContainerPages">
                <a id="facebookLogoHeaderPages" class="fa fa-facebook" href="https://www.facebook.com/gagyi.botond"></a>
            </div>

        </header>
    )
}

export default Header